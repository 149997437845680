<template>

  <Content>
    <template #contentTitle>
      {{ $t("warehouse.details_of_FCL_storage_booking") }}
    </template>
    <template #contentBody>
      <a-spin :spinning="state.loading">
        <div>
          <a-row :gutter=[0,16]>
            <a-col :span="24">{{ $t('warehouse.reservation_number')}}: {{state.appointmentNo}}</a-col>
            <a-col :sm="24"
                   :md="8"
                   :lg="8"
                   :xl="6"
                   :xxl="4">{{  $t('warehouse.date_created')}}:
              {{$filters.utcToCurrentTime(state.creationTime)}}
            </a-col>
            <a-col :sm="24"
                   :md="8"
                   :lg="8"
                   :xl="6"
                   :xxl="4">{{  $t('warehouse.update_time')}}:
              {{$filters.utcToCurrentTime(state.lastModificationTime)}}
            </a-col>
            <a-col :sm="24"
                   :md="8"
                   :lg="8"
                   :xl="12"
                   :xxl="16">{{ $t('warehouse.status')}}: {{$t($enumLangkey('fullContainerInWarehouseType',state.status))}}<span v-if="state.isTimeOut">({{ $t('warehouse.timed_out') }})</span></a-col>
            <a-col :sm="24"
                   :md="8"
                   :lg="8"
                   :xl="6"
                   :xxl="4">{{  $t('warehouse.appointment_warehouse')}}: {{state.warehouseNo}}</a-col>
            <a-col :sm="24"
                   :md="16"
                   :lg="16"
                   :xl="18"
                   :xxl="20">{{ $t('warehouse.warehouse_address')}}:
              {{getAddress(state.warehouseAddress)}}
              <span class="ml-5"
                    v-if="state.warehouseAddress">{{ $t('logistics.contacts') }}: {{getLinkman(state.warehouseAddress)}}</span>
            </a-col>
            <a-col :span="24">{{ $t('warehouse.container_type')}}: {{ $t($enumLangkey('containerType',state.containerType)) }}</a-col>
            <a-col :span="24">{{ $t('warehouse.container_no')}}: {{state.containerNo}}</a-col>
            <a-col :span="24">{{ $t('warehouse.date_of_reservation')}}:
              {{moment.tz(state.appointmentTime, state.warehouseTimeZone).format('YYYY-MM-DD')}}
              ({{$t('warehouse.local_time')}})
            </a-col>
          </a-row>
        </div>
        <div class="mt-2">
          <a-table :columns="columns"
                   :data-source="state.plans"
                   :scroll="{ x: 800}"
                   size="small"
                   style="min-height:223px;"
                   :pagination="false"
                   :rowKey="(record, index) => {return index;}">
            <template #serialNumber>
              {{ $t("warehouse.serial_number") }}
            </template>

            <template #inPlanInfo>
              <div>
                {{ $t("warehouse.plan_name") }}
              </div>
              <div>
                {{ $t("warehouse.in_warehouse_no") }}
              </div>
            </template>

            <template #inPlanInfoCustom="{record }">
              <div>
                {{record.planName}}
              </div>
              <div>
                {{record.planNo}}
              </div>
            </template>

            <template #inWarehouseStatusCustom="{record }">
              {{$t($enumLangkey('inWarehouseStatus',record.inWarehouseStatus))}}
            </template>

          </a-table>
        </div>
        <div class="mt-3">
          <div v-if="state.unloadingFee">
            <span><strong>{{$t('warehouse.landing_charges')}}:
                {{state.currencyCode}}
                {{state.currencySymbol}}
                {{state.unloadingFee}}
              </strong></span>
          </div>
          <div class="mt-1 text-warning">
            <InfoCircleOutlined />
            {{$t('warehouse.create_in_appointment_warning')}}
          </div>
        </div>
        <div class="mt-3 mb-5">
          <a-form layout="inline">
            <a-form-item :label="$t('logistics.add_driver_contact_information')">
              <a-select :allowClear="true"
                        show-search
                        optionFilterProp="search-key"
                        v-model:value="state.driverTelCodeId"
                        style="min-width:100px"
                        :placeholder="$t('logistics.area_code')">
                <a-select-option :value="item.id"
                                 v-for="item in dropDownData.countrys"
                                 :key="item.id"
                                 :search-key="item.cnName + item.enName+item.telCode"
                                 :title="getLanguageName(item)+'(+'+item.telCode+')'">
                  {{getLanguageName(item)}}(+{{item.telCode}})
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-input v-model:value="state.driverTelephone"></a-input>
            </a-form-item>
            <a-form-item>
              <a-button @click="hanldeSavePhone"
                        :loading="state.saveLoading">{{$t('common.save')}}</a-button>
            </a-form-item>
          </a-form>
        </div>
        <!-- 返回 -->
        <div class="mt-5 ">
          <a-row type="flex"
                 justify="center">
            <a-col class="mr-3">
              <a-button type="primary"
                        @click="handleBack "
                        ghost>{{$t('common.backtrack')}}</a-button>
            </a-col>
          </a-row>
        </div>
      </a-spin>
    </template>
  </Content>
</template>

<script>
import moment from "moment-timezone";
import { reactive, onMounted } from "vue";
import Content from "../../components/Content.vue";
import {
  Row, Col,
  Table, Spin,
  Button, Input, Form, Select, message
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import { getDetails } from "../../../api/modules/transportation/appointment";
import { useRoute, useRouter } from 'vue-router';
import { useTab } from "../../../hooks/tabs/index";
import { getAddressByLanguageV2, getLinkman } from "../../../utils/general"
import { useStore } from "vuex";
import { getName } from "../../../utils/general.js";
import { getGlobalCountrys } from "../../../api/modules/common/index"
import { updateDriverPhone } from "../../../api/modules/transportation/appointment";

export default ({
  name: "consignment_allot_plan_details",
  components: {
    Content,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    ASpin: Spin,
    AInput: Input,
    AFormItem: Form.Item,
    AForm: Form,
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();

    const columns = [
      {
        dataIndex: "serialNumber ",
        slots: {
          title: "serialNumber",
        },
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        dataIndex: "inPlanInfo",
        slots: {
          title: "inPlanInfo",
          customRender: "inPlanInfoCustom",
        },
        width: 250,
      },
      {
        title: () => vueI18n.t('warehouse.planned_quantity'),
        dataIndex: "totalWarehouseCount",
      },
      {
        title: () => vueI18n.t('warehouse.planned_box_quantity'),
        dataIndex: "totalContainerBoxCount",
      },
      {
        title: () => vueI18n.t('warehouse.product_quantity'),
        dataIndex: "totalCount",
      },
      {
        title: () => vueI18n.t('warehouse.status'),
        dataIndex: "inWarehouseStatus",
        slots: {
          customRender: "inWarehouseStatusCustom",
        },
      },
    ];

    const state = reactive({
      loading: false,
      saveLoading: false,

      appointmentNo: null,
      creationTime: null,
      lastModificationTime: null,
      status: null,
      isTimeOut: false,
      warehouseNo: null,
      warehouseAddress: null,
      containerType: null,
      containerNo: null,
      appointmentTime: null,
      plans: null,
      currencyCode: null,
      currencySymbol: null,
      unloadingFee: null,
      driverTelCode: null,
      driverTelephone: null,
      driverTelCodeId: null,
    })

    const dropDownData = reactive({
      countrys: [],
    })

    const funcGetCountrys = () => {
      getGlobalCountrys().then((res) => {
        if (res && res.result) {
          dropDownData.countrys = res.result;

          //预防老数据有"+"
          if (state.driverTelCode) {
            state.driverTelCode = state.driverTelCode?.toString().replace('+', "");
            state.driverTelCodeId = dropDownData.countrys
              .find(x => x.telCode == state.driverTelCode)?.id;
          }
        }
      }).catch()
    }

    const handleBack = async (record) => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "transport_appointment_list" });
    }

    const getAddress = (address) => {
      if (address) {
        return getAddressByLanguageV2(address, getters.language);
      }
    }

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    }

    const funcGetPageData = (id) => {
      state.loading = true;
      getDetails(id).then((res) => {
        let { result } = res;
        if (result) {
          Object.assign(state, result);
          funcGetCountrys();
        }
        state.loading = false;
      }).catch(() => {
        state.loading = false;
      })
    }

    const hanldeSavePhone = () => {
      if (!state.driverTelCodeId) {
        message.error(vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.area_code")]));
        return
      }

      if (!state.driverTelephone) {
        message.error(vueI18n.t("common.p0_is_required", [vueI18n.t("logistics.contact_information")]));
        return
      }

      if (!state.id) {
        return
      }

      state.driverTelCode = dropDownData.countrys
        .find(x => x.id == state.driverTelCodeId)?.telCode;
      state.saveLoading = true;
      updateDriverPhone(state).then(() => {
        message.success(vueI18n.t("common.succeed"));
        state.saveLoading = false;
      }).catch(() => {
        message.error(vueI18n.t("common.failed"));
        state.saveLoading = false;
      })
    }

    onMounted(async () => {
      let { id } = route.params;
      if (id) {
        funcGetPageData(id);
      }
    });

    return {
      getLanguageName,
      columns,
      state,
      getAddress,
      getLinkman,
      handleBack,
      moment,
      funcGetCountrys,
      dropDownData,
      hanldeSavePhone
    };
  }
})
</script>

<style lang="less" scoped>
</style>